
import { IModelUpdate } from "@/@core/services/interfaces/vehicle/models/IModelService";
import AvButton from "@/components/av-button/AvButton.vue";
import { AvSkeletonInput } from "@/components/av-skeleton";
import useModelHandler from "@/views/admin-panel/vehicle-version/model-edit-handler/useModelHandler";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import { Ref, ref } from "@vue/composition-api";
import {
  BAvatar,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BSidebar,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
// Interfaces

@Component({
  name: "ModelEdit",
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    AvButton,
    vSelect,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    AvSkeletonInput,
  },
  directives: {
    Ripple,
  },
})
export default class ModelEditHandler extends Vue {
  // Data
  @Prop({ required: true, default: false }) fabricanteOptions!: Array<string>;
  @Prop({ required: true, default: false }) modelId!: number;
  required = required;
  loading: Ref<boolean> = ref(false);
  isSavingChanges: boolean = false;
  modelLocal: IModelUpdate = {} as IModelUpdate;
  statusOptions = [
    { label: "Ativo", value: true },
    { label: "Inativo", value: false },
  ];

  resetSegmentLocal() {
    this.modelLocal.id = this.modelId;
  }

  clearForm() {
    this.modelLocal = {} as IModelUpdate;
  }

  // useSegmentHandler = useSegmentHandler(this);
  formValidation = formValidation(this.resetSegmentLocal, this.clearForm);
  useModelHandler = useModelHandler(this);

  // Computeds
  get isLoading(): boolean {
    return this.loading.value;
  }

  @Watch("modelId", { immediate: true })
  reset() {
    if (typeof this.modelId == "number") {
      this.clearForm();
    } else {
      this.clearForm();
    }
  }

  @Watch("isSidebarActive")
  async handleSidebar(val: boolean) {
    this.clearForm();
    if (!val) {
      setTimeout(() => {}, 350);
    }
  }

  created() {
    this.loadModelById();
  }

  async loadModelById() {
    this.loading.value = true;
    if (this.modelId) {
      let response = await this.useModelHandler.fetchGetModelById(this.modelId);

      const singleModel = response.data.Data;
      const model = {
        id: singleModel.Id,
        nome: singleModel.Nome,
        codigo: singleModel.Codigo,
        fabricanteId: singleModel.FabricanteId,
        ativo: singleModel.Ativo,
      };
      this.modelLocal = model;
    }
    this.loading.value = false;
  }

  async onSubmit() {
    this.loading.value = true;
    this.isSavingChanges = true;
    if (this.modelLocal.id) {
      await this.useModelHandler.fetchUpdateModel(this.modelLocal);
    } else {
      await this.useModelHandler.fetchCreateModel({
        nome: this.modelLocal.nome,
        codigo: this.modelLocal.codigo,
        fabricanteId: this.modelLocal.fabricanteId,
        ativo: this.modelLocal.ativo,
      });
    }

    this.clearForm();
    this.isSavingChanges = false;
    this.loading.value = false;
  }

  // Functions
  /**
   * Abrir ou fechar o sidebar de edição/criação
   */
  toggleSidebarHandler = (val: boolean): void => {
    this.$emit("updateIsSidebarActive", val);
  };

  /**
   * Mapeando o valor do select status de um objecto <{label, value}> para apenas value boolean
   */
  reduceValueStatus = (val: { label: string; value: boolean }): boolean => {
    return val.value;
  };
}
