
import AvButton from "@/components/av-button/AvButton.vue";
import { AvSkeletonInput } from "@/components/av-skeleton";
import formValidation from "@core/comp-functions/forms/form-validation";
import { IBrandRead } from "@core/services/interfaces/vehicle/brands/IBrandService";
import { required } from "@validations";
import { Ref, ref } from "@vue/composition-api";
import {
  BAvatar,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BSidebar,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import useBrandsHandler from "./useBrandsHandler";
// Interfaces

@Component({
  name: "BrandEditHandler",
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    AvButton,
    vSelect,
    flatPickr,
    AvSkeletonInput,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isBrandSideBarActive",
    event: "update:is-segment-handler-sidebar-active",
  },
})
export default class BrandEditHandler extends Vue {
  @Prop({ required: true, default: false })
  isBrandSideBarActive!: Ref<boolean>;
  @Prop({ required: true, default: [] }) statusOptions!: Array<{
    label: string;
    value: boolean;
  }>;
  @Prop({ required: true }) brandId!: number;
  @Prop({ required: true, default: () => {} })
  clearSegmentData!: () => void;

  // Data
  required = required;
  isSavingChanges: boolean = false;
  loading: Ref<boolean> = ref(false);
  brandLocal: IBrandRead = {} as IBrandRead;

  resetBrandLocal() {
    this.brandLocal.Id = this.brandId;
  }

  clearForm() {
    this.brandLocal = {} as IBrandRead;
  }

  useBrandsHandler = useBrandsHandler(this);
  formValidation = formValidation(this.resetBrandLocal, this.clearForm);

  // Computeds
  get isLoading(): boolean {
    return this.loading.value;
  }

  get isSidebarActive(): boolean {
    return this.isBrandSideBarActive.value;
  }

  @Watch("brandId", { immediate: true })
  reset() {
    this.clearForm();
  }

  // ------------------------------------------------
  // isBrandSideBarActive
  // * Limpar formulário se a barra lateral estiver fechada
  // * Consultar o grupo econômico se tiver passando o ID
  // ------------------------------------------------
  @Watch("isSidebarActive")
  async handleSidebar(val: boolean) {
    // ? Não redefina o evento até que a transição seja concluída
    this.clearForm();
    if (!val) {
      setTimeout(() => {}, 350);
    }

    if (this.isSidebarActive && this.brandId) {
      this.loading.value = true;
      const response = await this.useBrandsHandler
        .fetchGetBrandById(this.brandId)
        .finally(() => {
          this.loading.value = false;
        });
      this.brandLocal = response;
    }
  }

  async onSubmit() {
    this.isSavingChanges = true;
    this.loading.value = true;
    if (this.brandLocal.Id) {
      await this.useBrandsHandler.fetchUpdateBrand(this.brandLocal);
    } else {
      await this.useBrandsHandler.fetchCreateBrand(this.brandLocal);
    }

    this.$emit("loadingTable");
    this.$emit("refreshData");
    this.clearForm();
    this.loading.value = false;
    this.isBrandSideBarActive.value = false;
    this.isSavingChanges = false;
  }

  // Functions
  /**
   * Abrir ou fechar o sidebar de edição/criação de grupo econômico
   */
  toggleSidebarSegmentHandler = (val: boolean): void => {
    this.$emit("updateIsBrandSidebarActive", val);
  };

  /**
   * Mapeando o valor do select status de um objecto <{label, value}> para apenas value boolean
   */
  reduceValueStatus = (val: { label: string; value: boolean }): boolean => {
    return val.value;
  };
}
