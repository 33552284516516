
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { Component, Watch, Vue, Prop } from "vue-property-decorator";

@Component({
  name: "ValidateFilters",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
  },
})
export default class ValidateBrandFilters extends Vue {
  @Prop({ required: true, default: false }) statusFilter!: boolean | null;
  @Prop({ required: true, default: false }) statusOptions!: Array<any>;
  @Prop({ required: true, default: false }) fabricanteOptions!: Array<any>;
  rangeDate: any = null;
}
